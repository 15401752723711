import { AppRouter } from "./components/app-router"
function App() {
  return (
    <div>
      <AppRouter />
    </div>
  )
}

export default App
