import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { Home } from "./home"
import { SignUp } from "./signup"
import { SignIn } from "./sign-in"
export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/sign-in' element={<SignIn />} />
        <Route path='/signup' element={<SignUp />} />
      </Routes>
    </Router>
  )
}
